import { getWikiArticles } from "../api/wiki";

const initialAppBarSettings = {
  title: "N/A",
  loading: false,
  submenus: [],
  errors: {},
  submenuSelect: [],
  menus: [],
};

export const app = {
  namespaced: true,
  state: initialAppBarSettings,
  getters: {
    menus: (state) => state.menus,
  },
  mutations: {
    setTitle(state, value) {
      state.title = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setSubmenus(state, value) {
      state.submenus = value;
    },
    setSubmenuSelect(state, value) {
      state.submenuSelect = value;
    },
    setErrors(state, value) {
      state.errors = value;
    },
    setMenus(state, value) {
      state.menus = value;
    },
  },
  actions: {
    loadMenus: async ({ commit }) => {
      let items = [
        {
          url: "/",
          text: "Irányítópult",
          icon: "mdi-monitor-dashboard",
        },
        {
          url: { name: "ucrm.services.index" },
          text: "Végpontok",
          icon: "mdi-account-box",
        },
        {
          url: { name: "ucrm.deleteClient" },
          text: "Ügyfél törlés",
          icon: "mdi-account-box",
        },
        {
          url: { name: "ucrm.noinvoices.index" },
          text: "Nem készült számla",
          icon: "mdi-receipt",
        },
        {
          url: { name: "radius.ipchanger.index" },
          text: "Radius IP változtató",
          icon: "mdi-earth",
        },
        {
          url: { name: "tickets" },
          text: "Hibajegyek",
          icon: "mdi-toolbox-outline",
        },
        {
          url: { name: "ac2" },
          text: "Leromlott jelszintek",
          icon: "mdi-earth",
        },
        {
          url: { name: "unms.kozpontok" },
          text: "UNMS központok",
          icon: "mdi-earth",
        },
        {
          url: { name: "users" },
          text: "Felhasnzálók",
          icon: "mdi-account",
        },
        {
          url: { name: "wiki.article.create" },
          text: "Új Wiki cikk",
          icon: "mdi-file-document-outline",
        },

        {
          separator: true,
        },
      ];
      let articles = [];
      try {
        articles = await getWikiArticles().then((response) => {
          return response.data.map((el) => {
            return {
              url: { name: "wiki.article", params: { articleId: el.id } },
              text: el.title,
            };
          });
        });
      } catch (err) {
        console.log("Nem sikerült betölteni a cikkeket!");
        articles = [];
      }
      commit("setMenus", [...items, ...articles]);
    },
  },
};
