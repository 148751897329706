import Vue from 'vue'
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,
} from "vee-validate";
import hu from "vee-validate/dist/locale/hu.json";
import * as rules from "vee-validate/dist/rules";
import {API} from '../api'

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

extend('ucrmip', {
    // getMessage: field => {console.log('Szar'); 'The ' + field + ' value is not truthy.'},
    message: (field, params, data) => {console.log(`${field} ${JSON.stringify(params)} ${data}`); return `IP cím már létezik UCRM-ben, végpont ID: TODO`},
    validate: validateIP,
})

localize("hu", hu);

async function validateIP(value) {
    return await API.instance.get(`/ucrm/ip`, {params: {ip: value}})
      .then((response) => {console.log(response.data); return response})
      .catch(() => true)
  }
  
// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
