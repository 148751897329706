import axios from "axios";
// import rotuer from "../router";
import store from "../store";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${window.location.protocol}//${window.location.hostname}:${
          process.env.VUE_APP_BACKEND_PORT || 5000
        }/api/v1`
      : window.location.protocol + "//" + window.location.host + "/api/v1",
  timeout: process.env.VUE_APP_API_TIMEOUT || 40000,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use(
  (config) => {
    store.commit("app/setErrors", {});
    return config;
  },
  (err) => {
    store.commit("app/setErrors", JSON.stringify(err));
  }
);
// Respons handling
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      // Lejárt a token valószínűleg
      if (error.response.status === 403) {
        alert("Nincs jogosultságod!");
        return Promise.reject(error);
      } else if (error.response.status === 400) {
        store.commit("app/setErrors", error.response.data);
      }
    }
    return Promise.reject(error);
  }
);
export const API = {
  instance,
};
