<template>
  <v-alert v-if="errors.message" type="error" color="red" v-model="showErrors">
    <template v-if="errors.message === 'validation_error'">
      Hiba feldolgozáskor:

      <ul>
        <li v-for="(entry, name, i) in errors.errors" :key="i">
          {{ name }}:
          <ul>
            <li v-for="(error, i) in entry" :key="i">
              {{ error }}
            </li>
          </ul>
        </li>
      </ul>
    </template>
    <template v-if="errors.message === 'internal_server_error'">
      Belső szerver hiba (500): {{ errors.exception }}
      <br />
      <pre>
          {{ errors.traceback }}
      </pre>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: "errorHandler",
  data: () => ({
    showErrors: true
  }),
  computed: {
    errors: function() {
      return this.$store.state.app.errors;
    }
  }
};
</script>
