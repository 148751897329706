import { AuthAPI } from "../api/auth";

const initialUserData = {
  name: "",
  email: "",
};

export const auth = {
  namespaced: true,
  state: {
    userData: initialUserData,
    isLoggedIn: false,
  },
  mutations: {
    setLoginStatus(state, value) {
      state.isLoggedIn = value;
      if (value === false) {
        state.userData = initialUserData;
      }
    },
    setUserData(state, value) {
      state.userData = value;
    },
  },
  getters: {
    currentUser: (state) => state.userData,
  },
  actions: {
    login: async ({ commit }, payload) => {
      await AuthAPI.login(payload).then(() => commit("setLoginStatus", true));
    },
    getUserData: async ({ commit }) => {
      return AuthAPI.getClaims()
        .then((response) => {
          commit("setUserData", response.data);
          commit("setLoginStatus", true);
        })
        .catch((err) => {
          commit("setLoginStatus", false);
          return Promise.reject(err);
        });
    },
    logout: async ({ commit }) => {
      await AuthAPI.logout()
        .then(() => commit("setLoginStatus", false))
        .catch((err) => console.log(`Nem sikerült a kijelentkezés: ${err}`));
    },
  },
};
