const initialState = {messages: []}

export const messages = {
    namespaced: true,
    state: initialState,
    mutations: {
        addText(state, new_text){
            state.messages.push(new_text)
        },
        clearMessages(state){
            state.messages = []
        }
    },
    actions: {
        addText({commit}, new_text) {
            commit('addText', new_text)
        },
        clearMessages({commit}) {
            commit('clearMessages')
        }
    }
}