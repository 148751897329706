import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import { auth } from "./auth.module.js";
import { app } from "./app.module.js";
import { layout } from "./layout.module.js";
import { messages } from "./messages.module.js";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    messages,
    auth,
    layout
  }
});
