<template>
  <v-app v-if="isLoggedIn">
    <error-handler></error-handler>
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item v-for="item in menus" :key="item.title" link :to="item.url">
          <template v-if="!item.separator">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-else>
            <v-divider></v-divider>
          </template>

        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="indigo" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu v-if="currentUser" offset-y>
        <template v-slot:activator="{ on }" offset-y>
          <span v-on="on"> {{ currentUser.name || currentUser.username }}</span>
        </template>

        <v-list>
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Kijelentkezés</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container fluid>
        <v-alert type="error" dismissible v-if="errorMessages.length > 0" @input="clearAlerts">
          <ul>
            Hibák:
            <li v-for="error in errorMessages" :key="error">
              {{ error }}
            </li>
          </ul>
        </v-alert>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else>
    <router-view></router-view>
  </v-app>
</template>

<script>
import errorHandler from "./components/errorHandler.vue";

export default {
  name: "App",
  components: {
    errorHandler,
  },
  computed: {
    title: function () {
      return this.$store.state.app.title;
    },
    isLoggedIn: function () {
      return this.$store.state.auth.isLoggedIn;
    },
    errorMessages: function () {
      return this.$store.state.messages.messages;
    },
    currentUser: function () {
      return this.$store.getters["auth/currentUser"];
    },
    menus: function () {
      return this.$store.getters["app/menus"];
    }
  },
  data: () => ({
    drawer: true,
  }),
  mounted: function () {
    this.$store.dispatch("app/loadMenus");
  },
  methods: {
    logout: function () {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "Login" }));
    },
  },
};
</script>
<style>
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
</style>