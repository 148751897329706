import { API } from ".";

export const getWikiArticle = (articleId) => {
  return API.instance.get(`/wiki/article/${articleId}`);
};

export const getWikiArticles = () => {
  return API.instance.get("/wiki/article");
};

export const postWikiArticle = (data) => {
  return API.instance.post("/wiki/article", data);
};

export const patchWikiArticle = (articleId, data) => {
  return API.instance.patch(`/wiki/article/${articleId}`, data);
};
