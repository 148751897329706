

export const layout = {
    namespaced: true,
    state: {
        currentLayout: 'simple-layout'
    },
    mutations: {
        setCurrentLayout(state, value){
            state.currentLayout = value
        }
    },
    actions: {
        setCurrentLayout({commit}, value){
            commit('setCurrentLayout', value)
        }
    },
    getters: {
        currentLayout: state => state.currentLayout 
    }
}