import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/ucrm/services",
    name: "ucrm.services.index",
    component: () => import("../views/ucrm/services/index.vue"),
  },
  {
    path: "/ucrm/noinvoices",
    name: "ucrm.noinvoices.index",
    component: () => import("../views/ucrm/noinvoices/index.vue"),
  },
  {
    path: "/radius/ipchanger",
    name: "radius.ipchanger.index",
    component: () => import("../views/radius/ipchanger/index.vue"),
  },
  {
    path: "/radius/radacct/:username",
    name: "radius.radacct.view",
    component: () => import("../views/radius/radacct/view.vue"),
  },
  {
    path: "/ucrm/tickets",
    name: "tickets",
    component: () => import("../views/Tickets.vue"),
  },
  {
    path: "/wiki/create",
    name: "wiki.article.create",
    component: () => import("../views/EditiWikiPage.vue"),
  },
  {
    path: "/wiki/:articleId",
    name: "wiki.article",
    component: () => import("../views/WikiPage.vue"),
  },
  {
    path: "/wiki/:articleId/edit",
    name: "wiki.article.edit",
    component: () => import("../views/EditiWikiPage.vue"),
  },
  {
    path: "/wiki2/:articleId/edit",
    name: "wiki.article2.edit",
    component: () => import("../views/EditiWikiPage.vue"),
  },
  {
    path: "/ac2/",
    name: "ac2",
    component: () => import("../views/DeviceStat.vue"),
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/auth/Users.vue"),
  },
  {
    path: "/ucrm/deleteclient",
    name: "ucrm.deleteClient",
    component: () => import("../views/ucrm/DeleteClient.vue"),
  },
  {
    path: "/users/:id",
    name: "users.view",
    component: () => import("../views/auth/ViewUser.vue"),
  },
  {
    path: "/users/:id/edit",
    name: "users.edit",
    component: () => import("../views/auth/NewUser.vue"),
  },
  {
    path: "/unms/kozpontok",
    name: "unms.kozpontok",
    component: () => import("../views/UNMSSitesPage.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/PageNotFound.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "Login" || to.name === "ResetPassword") {
    next();
  } else {
    store
      .dispatch("auth/getUserData")
      .then(() => {
        next();
      })
      .catch((exc) => {
        console.log(exc);
        router.push({
          name: "Login",
          query: {
            message: "Lejárt a tokened, jelentkezz be újra!",
            prevroute:
              encodeURI(window.location.pathname) + window.location.search,
          },
        });
      });
  }
});

export default router;
