import { API } from ".";

export const AuthAPI = {
  login: async (payload) => {
    return await API.instance.post("/auth/login", payload);
  },
  logout: async () => {
    return await API.instance.post("/auth/logout");
  },
  getClaims: async () => {
    return await API.instance.get("/auth/user/claims");
  },
  getUsers: async () => {
    return await API.instance.get("/auth/user");
  },
  getUser: async (userId) => {
    return await API.instance.get(`/auth/user/${userId}`);
  },
  deleteUser: async (userId) => {
    return await API.instance.delete(`/auth/user/${userId}`);
  },
  activateUser: async (userId) => {
    return await API.instance.post(`/auth/user/${userId}/activate`);
  },
  updateUser: async (userId, data) => {
    return await API.instance.patch(`/auth/user/${userId}`, data);
  },
  createUser: async (data) => {
    return await API.instance.post(`/auth/user`, data);
  },
};
